import { getHeader, timeoutAPP, vendorUrl } from "@/constant/config"
import axios from "axios"

export default {
  state: {
    vendorList: []
  },
  mutations: {
    SET_VENDOR_LIST (state, obj) {
      state.vendorList = obj
    }
  },
  actions: {
    async getVendorList ({ commit }) {
      console.log(vendorUrl)
      console.log(getHeader())
      try {
        const data = await axios.get(vendorUrl, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_VENDOR_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  