import { financeUrl, getHeader, timeoutAPP } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    accountTypeList: [],
    accountType: {},
    accountList: [],
    account: {},
    cashBankType: {},
    cashBankTypeList: [],
    cashBank: { 
      id: null,
      cash_no: null,
      type_id: null,
      cash_date: null,
      debit: '0',
      credit: '0',
      remark: null,
      org_no: null,
      account_code: null,
      journal_code: null,
      notes: null,
      ref_no: null,
      status: null,
      lock: false,
    },
    journalTemplate: {
      id: null,
      name: null,
      template_date: null,
      description: null,
      org_no: null,
      type: null,
      details: [{
        id: null,
        template_id: null,
        account_code: null,
        remark: null,
        method: null,
        formula: null,
        org_no: null
      }]
    },
    activeModalAccount: false,
    activeModalAccountEdit: false,
    activeModalAccountType: false,
    activeModalAccountTypeEdit: false,
    activeModalCashBank: false,
    activeModalCashBankEdit: false,
    activeModalCashBankType: false,
    activeModalCashBankTypeEdit: false,
    activeModalImportBank: false,
    activeModalAddJournalTemplate: false,
    activeModalEditJournalTemplate: false
  },
  mutations: {
    SET_ACTIVE_MODAL_ADD_JOURNAL_TEMPLATE (state, obj) {
      state.activeModalAddJournalTemplate = obj
    },
    SET_ACTIVE_MODAL_EDIT_JOURNAL_TEMPLATE (state, obj) {
      state.activeModalEditJournalTemplate = obj
    },
    SET_ACTIVE_IMPORT_BANK (state, obj) {
      state.activeModalImportBank = obj
    },
    SET_ACTIVE_CASH_BANK (state, obj) {
      state.activeModalCashBank = obj
    },
    SET_ACTIVE_CASH_BANK_EDIT (state, obj) {
      state.activeModalCashBankEdit = obj
    },
    SET_CASH_BANK_TYPE (state, obj) {
      state.cashBankType = obj
    },
    SET_CASH_BANK_TYPE_LIST (state, obj) {
      state.cashBankTypeList = obj
    },
    SET_CASH_BANK (state, obj) {
      state.cashBank = obj
    },
    SET_ACTIVE_CASH_BANK_TYPE (state, obj) {
      state.activeModalCashBankType = obj
    },
    SET_ACTIVE_CASH_BANK_TYPE_EDIT (state, obj) {
      state.activeModalCashBankTypeEdit = obj
    },
    SET_ACCOUNT_TYPE_LIST (state, obj) {
      state.accountTypeList = obj
    },
    SET_ACCOUNT_LIST (state, obj) {
      state.accountList = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT (state, obj) {
      state.activeModalAccount = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT_EDIT (state, obj) {
      state.activeModalAccountEdit = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT_TYPE (state, obj) {
      state.activeModalAccountType = obj
    },
    SET_ACTIVE_MODAL_ACCOUNT_TYPE_EDIT (state, obj) {
      state.activeModalAccountTypeEdit = obj
    },
    SET_ACCOUNT_TYPE (state, obj) {
      state.accountType = obj
    },
    SET_ACCOUNT (state, obj) {
      state.account = obj
    },
    SET_JOURNAL_TEMPLATE (state, obj) {
      state.journalTemplate = obj
    }
  },
  actions: {
    async getJournalTemplate ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/journal/template/' + payload.id, { timeout: timeoutAPP, headers: getHeader() })
        commit('SET_JOURNAL_TEMPLATE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    activeModalEditJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_JOURNAL_TEMPLATE', true)
    },
    deactiveModalEditJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_JOURNAL_TEMPLATE', false)
    },
    activeModalAddJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_JOURNAL_TEMPLATE', true)
    },
    deactiveModalAddJournalTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_JOURNAL_TEMPLATE', false)
    },
    activeModalImportBank ({ commit }) {
      commit('SET_ACTIVE_IMPORT_BANK', true)
    },
    deactiveModalImportBank ({ commit }) {
      commit('SET_ACTIVE_IMPORT_BANK', false)
    },
    async getCashBank ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/cash-bank/' + payload.id, { headers: getHeader() })
        commit('SET_CASH_BANK', data.data.data)
        console.log(data.data.message)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    activeModalCashBank ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK', true)
    },
    deactiveModalCashBank ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK', false)
    },
    activeModalCashBankEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_EDIT', true)
    },
    deactiveModalCashBankEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_EDIT', false)
    },
    async getCashBankType ({ commit}, payload) {
      try {
        const data = await axios.get(financeUrl + '/cash-bank/type/' + payload.id, { headers: getHeader() })
        commit('SET_CASH_BANK_TYPE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getCashBankTypeList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/cash-bank/type', { headers: getHeader() })
        commit('SET_CASH_BANK_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    activeModalCashBankType ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE', true)
    },
    deactiveModalCashBankType ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE', false)
    },
    activeModalCashBankTypeEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE_EDIT', true)
    },
    deactiveModalCashBankTypeEdit ({ commit }) {
      commit('SET_ACTIVE_CASH_BANK_TYPE_EDIT', false)
    },
    async getAccountType ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/account/type/' + payload.id, { headers: getHeader() })
        commit('SET_ACCOUNT_TYPE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getAccount ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/account/' + payload.id, { headers: getHeader() })
        commit('SET_ACCOUNT', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    activeModalAccount ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT', true)
    },
    dectiveModalAccount ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT', false)
    },
    activeModalAccountEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_EDIT', true)
    },
    dectiveModalAccountEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_EDIT', false)
    },
    activeModalAccountType ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE', true)
    },
    dectiveModalAccountType ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE', false)
    },
    activeModalAccountTypeEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE_EDIT', true)
    },
    deactiveModalAccountTypeEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_ACCOUNT_TYPE_EDIT', false)
    },
    async getAccountTypeList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/account/type', { headers: getHeader() })
        commit('SET_ACCOUNT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getAccountList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/account', { headers: getHeader() })
        commit('SET_ACCOUNT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    }
  },
  getters: {
    cashBank: (state) => {
      const data = state.cashBank
      let debit = 0
      if (data.debit === null) {
        debit = '0'
      } else if (typeof data.debit === 'string') {
        debit = parseFloat(data.debit).toLocaleString('id-ID')
      } else {
        debit = data.debit.toLocaleString('id-ID')
      }
      let credit = 0
      if (data.credit === null) {
        credit = '0'
      } else if (typeof data.credit === 'string') {
        credit = parseFloat(data.credit).toLocaleString('id-ID')
      } else {
        credit = data.credit.toLocaleString('id-ID')
      }
      return {
        id: data.id,
        cash_no: data.cash_no,
        type_id: data.type_id,
        cash_date: data.cash_date,
        debit: debit,
        credit: credit,
        remark: data.remark,
        org_no: data.org_no,
        account_code: data.account_code,
        journal_code: data.journal_code,
        notes: data.notes,
        ref_no: data.ref_no,
        status: data.status,
        lock: data.lock
      }
    },
    accountTypeList: (state) => {
      const data = state.accountTypeList
      const loop = []
      for (let i = 0; i < data.length; i++) {
        loop.push({
          id: data[i].id,
          level: data[i].level,
          type_code: data[i].type_code,
          account_code: data[i].account_code,
          account_name: data[i].account_name,
          notes: data[i].notes,
          group: data[i].group,
          method: data[i].method,
          org_no: data[i].org_no,
          active: data[i].active,
          loader: false
        })
      }
      return loop
    }
  }
}
  