export default {
    state: {
        authUser: {},
    },
    getters: {
    //   getprofileinfo: (state) => state.profileinfo
    },
    mutations: {
        SET_AUTH_USER (state, obj) {
            state.authUser = obj
        }
    },
    actions: {
        setUserObject: ({ commit }, payload) => {
            commit('SET_AUTH_USER', payload)
        }
    }
};
  