import { getHeader, roleUrl, unitUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    roleList: [],
    activeModalAddUser: false,
    activeModalEditUser: false
  },
  getters: {
    roleList: (state) => state.roleList
  },
  mutations: {
    SET_ROLE_LIST (state, obj) {
      state.roleList = obj
    },
    SET_ACTIVE_MODAL_ADD_USER (state, obj) {
      state.activeModalAddUser = obj
    },
    SET_ACTIVE_MODAL_EDIT_USER (state, obj) {
      state.activeModalEditUser = obj
    }
  },
  actions: {
    activeModalAddUser ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_USER', true)
    },
    deactiveModalAddUser ({ commit }) {
      commit('SET_ACTIVE_MODAL_ADD_USER', false)
    },
    activeModalEditUser ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_USER', true)
    },
    deactiveModalEditUser ({ commit }) {
      commit('SET_ACTIVE_MODAL_EDIT_USER', false)
    },
    async getRoleList ({ commit }) {      
      try {
        console.log(roleUrl)
        const data = await axios.get(roleUrl, { headers: getHeader() })
        console.log(data)
        commit('SET_ROLE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  