export default {
  state: {
    activeModalNotificationTemplate: false,
    activeModalNotificationTemplateEdit: false
  },
  mutations: {
    SET_ACTIVE_MODAL_NOTIFICATION_TEMPLATE (state, obj) {
      state.activeModalNotificationTemplate = obj
    },
    SET_ACTIVE_MODAL_NOTIFICATION_TEMPLATE_EDIT (state, obj) {
      state.activeModalNotificationTemplateEdit = obj
    }
  },
  actions: {
    activeModalNotificationTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_NOTIFICATION_TEMPLATE', true)
    },
    deactiveModalNotificationTemplate ({ commit }) {
      commit('SET_ACTIVE_MODAL_NOTIFICATION_TEMPLATE', false)
    },
    activeModalNotificationTemplateEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_NOTIFICATION_TEMPLATE_EDIT', true)
    },
    deactiveModalNotificationTemplateEdit ({ commit }) {
      commit('SET_ACTIVE_MODAL_NOTIFICATION_TEMPLATE_EDIT', false)
    }
  }
}
