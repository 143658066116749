import { bookUrl, getHeader, promoUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    activeModalUnitCustomer: false,
    activeModalUnitCrew: false,
    activeModalRefund: false,
    activeModalEditRefund: false,
    activeModalPaymentInvoice: false,
    activeModalBookUnitSummary: false,
    activeModalBookShow: false,
    activeModalPaymentEdit: false,
    activeModalRating: false,
    activeModalRequest: false,
    bookTypeList: [],
    bookUnitTypeList: [],
    promoList: [],
    bookListRaw: [],
    bookRefundId: {
      id: null,
      refund_date: null,
      amount: '0'
    },
    book: {
      id: null,
      book_no: null,
      book_date: null,
      type_id: null
    },
    payment: {}
  },
  getters: {
    bookTypeList: (state) => state.bookTypeList,
    bookTypeSelect: (state) => {
      const array = state.bookTypeList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].id,
          label: array[i].name
        })
      }
      return loop
    },
    bookUnitTypeSelect: (state) => {
      const array = state.bookUnitTypeList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].id,
          label: array[i].name
        })
      }
      return loop
    },
    bookRefundById: (state) => {
      const book = state.bookRefundId
      const bookLength = Object.keys(state.bookRefundId).length
      let amount = '0'
      if (bookLength > 0) {
        if (book.amount === null) {
          amount = '0'
        } else if (typeof book.amount === 'string') {
          amount = parseFloat(book.amount).toLocaleString('id-ID')
        } else {
          amount = book.amount.toLocaleString('id-ID')
        }
      }
      return {
        id: book.id,
        book_no: book.book_no,
        notes: book.notes,
        account_name: book.account_name,
        account_no: book.account_no,
        bank: book.bank,
        amount: amount,
        refund_date: book.refund_date,
        approve_by: book.approve_by,
        approve_date: book.approve_date,
        memo_no: book.memo_no,
        status: book.status,
        lock: book.lock,
        org_no: book.org_no,
        files: book.files
      }
    }
  },
  mutations: {
    activeModalRequest (state, obj) {
      state.activeModalRequest = obj
    },
    activeModalRating (state, obj) {
      state.activeModalRating = obj
    },
    activeModalPaymentEdit (state, obj) {
      state.activeModalPaymentEdit = obj
    },
    activeModalBookShow (state, obj) {
      state.activeModalBookShow = obj
    },
    activeModalBookUnitSummary (state, obj) {
      state.activeModalBookUnitSummary = obj
    },
    activeModalPaymentInvoice (state, obj) {
      state.activeModalPaymentInvoice = obj
    },
    openModalUnitCustomer (state) {
      state.activeModalUnitCustomer = true
    },
    closeModalUnitCustomer (state) {
      state.activeModalUnitCustomer = false
    },
    openModalUnitCrew (state) {
      state.activeModalUnitCrew = true
    },
    closeModalUnitCrew (state) {
      state.activeModalUnitCrew = false
    },
    openModalRefund (state) {
      state.activeModalRefund = true
    },
    closeModalRefund (state) {
      state.activeModalRefund = false
    },
    activeModalEditRefund (state, obj) {
      state.activeModalEditRefund = obj
    },
    SET_BOOK_TYPE_LIST (state, obj) {
      state.bookTypeList = obj
    },
    SET_BOOK_UNIT_TYPE_LIST (state, obj) {
      state.bookUnitTypeList = obj
    },
    SET_PROMO_LIST (state, obj) {
      state.promoList = obj
    },
    SET_BOOK_LIST_RAW (state, obj) {
      state.bookListRaw = obj
    },
    SET_BOOK_REFUND_ID (state, obj) {
      state.bookRefundId = obj
    },
    SET_BOOK (state, obj) {
      state.book = obj
    },
    SET_PAYMENT (state, obj) {
      state.payment = obj
    }
  },
  actions: {
    activeModalRequest ({ commit }) {
      commit('activeModalRequest', true)
    },
    deactiveModalRequest ({ commit }) {
      commit('activeModalRequest', false)
    },
    activeModalRating ({ commit }) {
      commit('activeModalRating', true)
    },
    deactiveModalRating ({ commit }) {
      commit('activeModalRating', false)
    },
    activeModalPaymentEdit ({ commit }) {
      commit('activeModalPaymentEdit', true)
    },
    deactiveModalPaymentEdit ({ commit }) {
      commit('activeModalPaymentEdit', false)
    },
    activeModalBookShow ({ commit }) {
      commit('activeModalBookShow', true)
    },
    deactiveModalBookShow ({ commit }) {
      commit('activeModalBookShow', false)
    },
    activeModalBookUnitSummary ({ commit }) {
      commit('activeModalBookUnitSummary', true)
    },
    deactiveModalBookUnitSummary ({ commit }) {
      commit('activeModalBookUnitSummary', false)
    },
    activeModalPaymentInvoice ({ commit }) {
      commit('activeModalPaymentInvoice', true)
    },
    deactiveModalPaymentInvoice ({ commit }) {
      commit('activeModalPaymentInvoice', false)
    },
    async getBookRefundID ({ commit }, payload) {
      try {
        console.log(bookUrl + '/refund/' + payload.id)
        const data = await axios.get(bookUrl + '/refund/' + payload.id, { headers: getHeader() })
        commit('SET_BOOK_REFUND_ID', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    openModalEditRefund ({ commit}) {
      commit('activeModalEditRefund', true)
    },
    closeModalEditRefund ({ commit }) {
      commit('activeModalEditRefund', false)
    },
    openModalRefund ({ commit }) {
      commit('openModalRefund')
    },
    closeModalRefund ({ commit }) {
      commit('closeModalRefund')
    },
    openModalUnitCustomer ({ commit }) {
      commit('openModalUnitCustomer')
    },
    closeModalUnitCustomer ({ commit }) {
      commit('closeModalUnitCustomer')
    },
    openModalUnitCrew ({ commit }) {
      commit('openModalUnitCrew')
    },
    closeModalUnitCrew ({ commit }) {
      commit('closeModalUnitCrew')
    },
    async getBookTypeList ({ commit }) {
      try {
        const data = await axios.get(bookUrl + '/type', { headers: getHeader() })
        commit('SET_BOOK_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getBookUnitTypeList ({ commit }) {
      try {
        const data = await axios.get(bookUrl + '/unit/type', { headers: getHeader() })
        commit('SET_BOOK_UNIT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getPromoList ({ commit }) {
      try {
        const data = await axios.post(promoUrl + '/list', { date: null }, { headers: getHeader() })
        commit('SET_PROMO_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getBookListRaw ({ commit}, payload) {
      try {
        const data = await axios.get(bookUrl + '/unit?begin=' + payload.begin + '&end=' + payload.end, { headers: getHeader() })
        commit('SET_BOOK_LIST_RAW', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getBook ({ commit }, payload) {
      try {
        const data = await axios.get(bookUrl + '/' + payload.id, { headers: getHeader() })
        commit('SET_BOOK', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getPayment ({ commit }, payload) {
      try {
        const data = await axios.get(bookUrl + '/payment/' + payload.id, { headers: getHeader() })
        commit('SET_PAYMENT', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    }
  }
}
  