import { getHeader, productUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    productTypeList: [],
    productCategoryList: [],
    productList: [],
    productListLite: [],
    productVariantList: [],
    productVariantAddonList: []
  },
  mutations: {
    SET_PRODUCT_TYPE_LIST (state, obj) {
      state.productTypeList = obj
    },
    SET_PRODUCT_CATEGORY_LIST (state, obj) {
      state.productCategoryList = obj
    },
    SET_PRODUCT_LIST (state, obj) {
      state.productList = obj
    },
    SET_PRODUCT_LIST_LITE (state, obj) {
      state.productListLite = obj
    },
    SET_PRODUCT_VARIANT_LIST (state, obj) {
      state.productVariantList = obj
    },
    SET_PRODUCT_VARIANT_ADDON_LIST (state, obj) {
      state.productVariantAddonList = obj
    }
  },
  actions: {
    async getProductTypeList ({ commit }) {
      try {
        const data = await axios.get(productUrl + '/type', { headers: getHeader() })
        commit('SET_PRODUCT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getProductCategoryList ({ commit }) {
      try {
        const data = await axios.get(productUrl + '/category', { headers: getHeader() })
        commit('SET_PRODUCT_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getProductList ({ commit }) {
      try {
        const data = await axios.get(productUrl, { headers: getHeader() })
        commit('SET_PRODUCT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getProductListLite ({ commit }) {
      try { 
        const data = await axios.get(productUrl + '/list', { headers: getHeader() })
        commit('SET_PRODUCT_LIST_LITE', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getProductVariantList ({ commit }) {
      try {
        const data = await axios.post(productUrl + '/variant/list', {}, { headers: getHeader() })
        commit('SET_PRODUCT_VARIANT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    },
    async getProductVariantAddOnList ({ commit }, payload) {
      const body = {
        type: payload
      }
      try {
        const data = await axios.post(productUrl + '/variant/list', body, { headers: getHeader() })
        commit('SET_PRODUCT_VARIANT_ADDON_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.response)
      }
    }
  },
  getters: {
    productList: (state) => state.productList,
    productTypeList: (state) => state.productTypeList,
    productCategoryList: (state) => state.productCategoryList,
    productListLite: (state) => state.productListLite,
    productSelect: (state) => {
      const array = state.productListLite
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].product_no,
          label: array[i].product_name
        })
      }
      return loop
    },
    productVariantSelect: (state) => {
      const array = state.productVariantList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].product_sku !== null) {
          loop.push({
            value: array[i].product_sku,
            label: array[i].product + ' - ' + array[i].variant_name
          })
        }
      }
      return loop
    },
    producVariantSelectMeta: (state) => {
      var loop = []
      var array = state.productVariantList
      for (var x = 0; x < array.length; x++) {
        if (array[x].product_sku !== null) {
          var obj = {
            product: array[x].product + ' - ' + array[x].variant_name,
            meta: {
              code: array[x].product_sku
            }
          }
          loop.push(obj)
        }
      }
      return loop
    },
    productVariantAddonSelect: (state) => {
      const array = state.productVariantAddonList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].product_sku !== null) {
          loop.push({
            value: array[i].product_sku,
            label: array[i].product + ' - ' + array[i].variant_name
          })
        }
      }
      return loop
    },
    productAddonSelect: (state) => {
      const array = state.productVariantAddonList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        if (array[i].product_sku !== null) {
          loop.push({
            value: array[i].product_no,
            label: array[i].product
          })
        }
      }
      return loop
    }
  }
};
  