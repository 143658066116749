import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/login", name: 'login', component: () => import("@/views/auth/login/login3.vue"), meta: { requiresAuth: false }},
  {
    path: "/",
    name: "Layout",
    redirect: "/home",
    component: () => import("@/Layout/index.vue"),
    children: [
      { path: "/home", name: "home", component: () => import("@/views/home/index.vue"), meta: {  requiresAuth: true, hide: true } },
      // User
      { path: '/user', name: 'user', component: () => import("@/views/user/index.vue"), meta: { requiresAuth: true, groupParent: "master" } },
      { path: '/role', name: 'role', component: () => import("@/views/user/RolePermission.vue"), meta: { requiresAuth: true, groupParent: "master" } },
      // product
      { path: '/product', name: 'product', component: () => import("@/views/product/index.vue"), meta: { requiresAuth: true, groupParent: "inventory" } },
      { path: '/product/add', name: 'product-add', component: () => import("@/views/product/AddProduct.vue"), meta: { requiresAuth: true, groupParent: "inventory" } },
      { path: '/product/:id/edit', name: 'product-edit', component: () => import("@/views/product/EditProduct.vue"), props: true, meta: { requiresAuth: true, groupParent: "inventory" } },
      { path: '/product/summary', name: 'product-summary', component: () => import("@/views/product/ProductSummary.vue"), meta: { requiresAuth: true, hide: false, groupParent: "inventory" } },
      // purchase
      { path: '/purchase', name: 'purchase', component: () => import('@/views/purchase/index.vue'), meta: { requiresAuth: true, hide: false, groupParent: "inventory" } },
      { path: '/purchase/add', name: 'purchase-add', component: () => import('@/views/purchase/AddPurchase.vue'), meta: { requiresAuth: true, hide: false, groupParent: "inventory" } },
      { path: '/purchase/:id/edit', name: 'purchase-edit', component: () => import('@/views/purchase/EditPurchase.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "inventory" } },
      // unit
      { path: '/unit', name: 'unit', component: () => import('@/views/unit/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "unit"} },
      { path: '/unit/add', name: 'unit-add', component: () => import('@/views/unit/AddUnit.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      { path: '/unit/:id/edit', name: 'unit-edit', component: () => import('@/views/unit/EditUnit.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      // unit check
      { path: '/unit/check', name: 'unit-check', component: () => import('@/views/unit/check/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      { path: '/unit/check/add', name: 'check-add', component: () => import('@/views/unit/check/AddUnitCheck.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      { path: '/unit/check/:id/edit', name: 'check-edit', component: () => import('@/views/unit/check/EditUnitCheck.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      // unit trip check
      { path: '/unit/trip-check', name: 'trip-check', component: () => import('@/views/unit/tripCheck/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "unit"} },
      { path: '/unit/trip-check/add', name: 'tripcheck-add', component: () => import('@/views/unit/tripCheck/AddUnitTripCheck.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      { path: '/unit/trip-check/:id/edit', name: 'tripcheck-edit', component: () => import('@/views/unit/tripCheck/EditUnitTripCheck.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      // unit refuel
      { path: '/unit/refuel', name: 'refuel', component: () => import('@/views/unit/refuel/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      { path: '/unit/refuel/:id', name: 'refuel-edit', component: () => import('@/views/unit/refuel/EditUnitRefuel.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      // Unit report
      { path: '/unit/report-summary', name: 'unit-report-summary', component: () => import('@/views/unit/report/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "unit"} },
      // booking
      { path: '/sale/book', name: 'booking', component: () => import('@/views/sale/booking/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/book/add', name: 'booking-add', component: () => import('@/views/sale/booking/AddBooking.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/book/schedule', name: 'booking-schedule', component: () => import('@/views/sale/booking/Schedule.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/book/request', name: 'booking-request', component: () => import('@/views/sale/request/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/book/:id/edit', name: 'booking-edit', component: () => import('@/views/sale/booking/EditBooking.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/book/:id/preview', name: 'booking-preview', component: () => import('@/views/sale/booking/PreviewBooking.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      // Invoice
      { path: '/sale/invoice', name: 'invoice', component: () => import('@/views/sale/invoice/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/invoice/add', name: 'invoice-add', component: () => import('@/views/sale/invoice/AddInvoice.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/invoice/:id/edit', name: 'invoice-edit', component: () => import('@/views/sale/invoice/EditInvoice.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/invoice/:id/preview', name: 'invoice-preview', component: () => import('@/views/sale/invoice/PreviewInvoice.vue'), props: true, meta: { requiresAuth: true, hide: true, groupParent: "sale"} },
      { path: '/trip/on-trip', name: 'trip-on-trip', component: () => import('@/views/trip/OnTrip.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "trip"}},
      // Sales Refund
      { path: '/sale/refund', name: 'refund', component: () => import('@/views/sale/refund/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/sale/refund/:id', name: 'refund-form', component: () => import('@/views/sale/refund/Refund.vue'), props: true, meta: { requiresAuth: true, hide: true, groupParent: "sale"} },
      // Customer
      { path: '/customer', name: 'customer', component: () => import('@/views/customer/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "customer"} },
      { path: '/customer/add', name: 'customer-add', component: () => import('@/views/customer/AddCustomer.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "customer"} },
      { path: '/customer/:id/edit', name: 'customer-edit', component: () => import('@/views/customer/EditCustomer.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "customer"} },
      // Promo
      { path: '/promo', name: 'promo', component: () => import('@/views/promo/index.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/promo/add', name: 'promo-add', component: () => import('@/views/promo/AddPromo.vue'), props: false, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      { path: '/promo/:id/edit', name: 'promo-edit', component: () => import('@/views/promo/EditPromo.vue'), props: true, meta: { requiresAuth: true, hide: false, groupParent: "sale"} },
      // Finance
      { path: '/finance/account', name: 'account', component: () => import('@/views/finance/account/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      // Finance
      { path: '/finance/cash-bank', name: 'cash', component: () => import('@/views/finance/cashbank/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      { path: '/finance/cash-bank/type', name: 'cash-type', component: () => import('@/views/finance/cashbank/type/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      { path: '/finance/payment', name: 'finance-payment', component: () => import('@/views/finance/payment/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      { path: '/finance/buku-besar', name: 'finance-buku-besar', component: () => import('@/views/finance/buku-besar/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      // Journal
      { path: '/finance/journal', name: 'finance-journal', component: () => import('@/views/finance/journal/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      { path: '/finance/journal/add', name: 'finance-journal-add', component: () => import('@/views/finance/journal/AddJournal.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      { path: '/finance/journal/:id/edit', name: 'finance-journal-edit', component: () => import('@/views/finance/journal/EditJournal.vue'), props: true, meta: { requiresAuth: true, hide: true, groupParent: "finance"} },
      // Employee
      { path : '/employee/captain-summary', name: 'captain-summary', component: () => import('@/views/employee/report/CaptainSummary.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "employee"} },
      // Lock
      { path: '/lock', name: 'lock', component: () => import('@/views/lock/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "master"} },
      // Notif
      { path: '/notification/template', name: 'notification-template', component: () => import('@/views/notification/template/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "master"} },
      { path: '/notification', name: 'notification', component: () => import('@/views/notification/index.vue'), props: false, meta: { requiresAuth: true, hide: true, groupParent: "master"} }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});
router.beforeEach((to, from, next) => {
  const titleText = to.name
  const words = titleText.split("-")
  const wordslength = words.length
  for (let i = 0; i < wordslength; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }

  document.title = "Seadoo Safari  - " + words
  if (to.meta.requiresAuth === false) {
    next()
  } else {
    const authUser = JSON.parse(window.localStorage.getItem('authUser'))
    if (authUser === null) {
      next({ name: 'login' })
    } else {
      next()
    }
  }
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
