import { getHeader, purchaseUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    purchaseTypeList: [],
    modalPaymentModal: false,
    purchaseSerialNumberList: []
  },
  getters: {
    getPurchaseTypeList: (state) => state.purchaseTypeList,
    purchaseSerialNumberSelect: (state) => {
      const array = state.purchaseSerialNumberList
      const loop = []
      for (let i = 0; i < array.length; i++) {
        loop.push({
          value: array[i].serial_number,
          label: array[i].serial_number
        })
      }
      return loop
    }
  },
  mutations: {
    SET_PURCHASE_TYPE_LIST (state, obj) {
      state.purchaseTypeList = obj
    },
    openPaymentModal (state) {
      state.modalPaymentModal = true
    },
    closePaymentModal (state) {
      state.modalPaymentModal = false
    },
    SET_PURCHASE_SERIAL_NUMBER (state, obj) {
      state.purchaseSerialNumberList = obj
    }
  },
  actions: {
    openPaymentModal ({ commit }) {
      commit('openPaymentModal')
    },
    closePaymentModal ({ commit }) {
      commit('closePaymentModal')
    },
    async getPurchaseTypeList ({ commit }) {
      try {
        console.log(purchaseUrl + '/type')
        const data = await axios.get(purchaseUrl + '/type', { headers: getHeader() })
        console.log(data.data.data)
        commit('SET_PURCHASE_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    async getPurchaseSerialNumber ({ commit }) {
      try {
        const data = await axios.get(purchaseUrl + '/deliver/group-by-sn', { headers: getHeader() })
        commit('SET_PURCHASE_SERIAL_NUMBER', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  