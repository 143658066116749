export const domain = process.env.VUE_APP_DOMAIN
export const apiDomain = domain + '/api/'
export const timeoutAPP = process.env.VUE_APP_TIMEOUT

export const loginUrl = domain + '/auth/login'
export const userUrl = apiDomain + 'user'
export const roleUrl = apiDomain + 'role'
export const permissionUrl = apiDomain + 'permission'
export const userTokenUrl = apiDomain + 'user/token'
export const companyStartUrl = domain + 'company'
export const productUrl = apiDomain + 'rental/product'
export const uomUrl = apiDomain + 'uom'
export const customerUrl = apiDomain + 'customer'
export const employeeUrl = apiDomain + 'employee'
export const vendorUrl = apiDomain + 'vendor'
export const purchaseUrl = apiDomain + 'rental/purchase'
export const warehouseUrl = apiDomain + 'warehouse'
export const taxUrl = apiDomain + 'tax'
export const settingUrl = apiDomain + 'rental/setting'
export const unitUrl = apiDomain + 'unit'
export const bookUrl = apiDomain + 'book'
export const domainBookUrl = domain  + '/book'
export const paymentMethodUrl = apiDomain + 'payment-method'
export const promoUrl = apiDomain + 'rental/promo'
export const cashUrl = apiDomain + 'cash'
export const bankUrl = apiDomain + 'bank'
export const organizationUrl = apiDomain + 'organization'
export const unitCheckUrl = apiDomain + 'unit/check'
export const reportUrl = apiDomain + 'report'
export const socialMediaUrl = apiDomain + 'social-media'
export const financeUrl = apiDomain + 'finance'
export const lockUrl = apiDomain + 'lock'
export const notificationUrl = apiDomain + 'notification'
export const exportUrl = apiDomain + 'export'

export const companyNo = 'C0001'

export const getHeader = function () {
  const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + tokenData.token
  }
  return headers
}
