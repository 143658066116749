import { customerUrl, getHeader, socialMediaUrl } from "@/constant/config";
import axios from "axios";

export default {
  state: {
    customerList: [],
    activeCustomerModal: false,
    loader: false,
    socialMediaList: []
  },
  getters: {
    customerList: (state) => state.customerList
  },
  mutations: {
    SET_CUSTOMER_LIST (state, obj) {
      state.customerList = obj
    },
    openModalCustomer (state) {
      state.activeCustomerModal = true
    },
    closeModalCustomer (state) {
      state.activeCustomerModal = false
    },
    SET_SOCIAL_MEDIA_LIST (state, obj) {
      state.socialMediaList = obj
    }
  },
  actions: {
    async getCustomerList ({ commit }) {
      try {
        const data = await axios.get(customerUrl, { headers: getHeader() })
        commit('SET_CUSTOMER_LIST', data.data.data)
        console.log(data.data.message)
        console.log(data.data.data)
      } catch (error) {
        console.log(error)
      }
    },
    openModalCustomer ({ commit }) {
      commit('openModalCustomer')
    },
    closeModalCustomer ({ commit }) {
      commit('closeModalCustomer')
    },
    async getSocialMediaList ({ commit }) {
      try {
        const data = await axios.get(socialMediaUrl, { headers: getHeader() })
        console.log(data.data.data)
        commit('SET_SOCIAL_MEDIA_LIST', data.data.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
  